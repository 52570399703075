import createEnv, { type Environment } from '@pkg/env';

/*
|==========================================================================
| Configuration
|==========================================================================
|
| Environment variables are used to configure the application, this is a mapping
| of those variables to a configuration object.
|
*/

const env = createEnv(import.meta.env);

const extractSentryProjectId = (dsn: string): number => {
  const parts = dsn.split('/');
  const projectId = parts[parts.length - 1];

  const projectIdNum = Number(projectId);

  if (Number.isNaN(projectIdNum)) {
    throw new Error(`Invalid project ID: ${projectId}`);
  }

  return projectIdNum;
};

/*
|------------------
| Utils
|------------------
*/

type LoggingType = 'http' | 'console' | 'none';

/*
|------------------
| Configuration
|------------------
*/

const config = {
  logging: {
    type: env<LoggingType>('VITE_LOG_TYPE'),
    level: env('VITE_LOG_LEVEL'),
  },
  releaseVersion: env('VITE_RELEASE_VERSION'),
  environment: env<Environment>('VITE_ENVIRONMENT'),
  posthog: {
    apiKey: env('VITE_POSTHOG_API_KEY'),
    instance: env('VITE_POSTHOG_INSTANCE'),
  },
  frigade: {
    apiKey: env('VITE_FRIGADE_API_KEY'),
    apiUrl: env('VITE_FRIGADE_URL'),
  },
  api: {
    url: env('VITE_API_URL'),
  },
  auth0: {
    url: `https://${env('VITE_AUTH0_DOMAIN')}`,
    clientId: env('VITE_AUTH0_CLIENT_ID'),
    audience: env('VITE_AUTH0_AUDIENCE'),
  },
  githubApp: {
    clientId: env('VITE_GITHUB_APP_CLIENT_ID'),
    installUrl: env('VITE_GITHUB_APP_INSTALL_URL'),
  },
  sentry: {
    org: 'joggr',
    enabled: env<boolean>('VITE_SENTRY_ENABLED'),
    dsn: env('VITE_SENTRY_DSN'),
    projectId: extractSentryProjectId(env('VITE_SENTRY_DSN')),
    profileSampleRate: env<number>('VITE_SENTRY_PROFILE_SAMPLE_RATE'),
    traceSampleRate: env<number>('VITE_SENTRY_TRACE_SAMPLE_RATE'),
    replaysSessionSampleRate: env<number>(
      'VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE'
    ),
    replaysErrorSampleRate: env<number>(
      'VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE'
    ),
  },
  cdn: {
    url: env('VITE_CDN_URL'),
  },
  url: window.location.origin,
};

export type Config = typeof config;

export default config;
