import _ from 'lodash';
import React from 'react';

import { DashDraftProvider } from '@stargate/lib/dashdraft';
import { Outlet } from '@stargate/routes';

import { useJDocContext } from '../hooks/use-jdoc-ctx';

/**
 * Provider the DashDraftProvider to the page to allow the page to use the DashDraftContent & Hooks
 */
export const JoggrDocProvider: React.FC = () => {
  const jdocParams = useJDocContext();
  return (
    <DashDraftProvider
      editorOptions={{
        editable: jdocParams.mode !== 'view',
      }}
    >
      <Outlet />
    </DashDraftProvider>
  );
};
