import * as Spotlight from '@spotlightjs/spotlight';
import config from '@stargate/config';
import { devLogger } from '@stargate/logger';
import { create } from 'zustand';

export interface UseDevToolsHook {
  /**
   * Whether the DevTools are enabled
   */
  enabled: boolean;

  /**
   * Toggle the DevTools, including:
   * - ReactQuery DevTools
   * - Frigade (custom) DevTools
   *
   * @returns void
   */
  toggle: () => void;
}

/**
 * Hook for managing Joggr's DevTools
 */
export const useDevTools = (): UseDevToolsHook => {
  const { enabled, toggle } = useStore((state) => {
    return {
      enabled: state.enabled,
      toggle: state.toggle,
    };
  });

  return { enabled, toggle };
};

/*
|------------------
| Utils
|------------------
*/

const useStore = create<{ enabled: boolean; toggle: () => void }>((set) => ({
  enabled: false,
  toggle: () => {
    Spotlight;
    set((state) => ({ enabled: !state.enabled }));
  },
}));

declare global {
  interface Window {
    toggleDevTools: () => void;
  }
}

if (typeof window !== 'undefined') {
  // biome-ignore lint/complexity/useArrowFunction: <explanation>
  window.toggleDevTools = function () {
    if (config.environment !== 'production') {
      const enabled = useStore.getState().enabled;
      useStore.setState({ enabled: !enabled });
      devLogger.info(`DevTools are now ${!enabled ? 'enabled' : 'disabled'}`);
    }
  };
}
