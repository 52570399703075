import React from 'react';
import { useIntl as useReactIntl } from 'react-intl';

import type { LocaleId } from '../types';

/**
 * A thin wrapper around `useIntl` that provides types based on the available translations.
 */
export const useLocalization = () => {
  const intl = useReactIntl();
  type P = Parameters<typeof intl.formatMessage>;

  const formatMessage = React.useCallback(
    (id: LocaleId, values?: P[1], opts?: P[2]) => {
      return intl.formatMessage(
        { id, defaultMessage: 'Missing Translation' },
        values,
        opts
      ) as string;
    },
    [intl]
  );

  return {
    ...intl,
    formatMessage,
  };
};
