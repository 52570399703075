import {
  darken,
  formLabelClasses,
  inputLabelClasses,
  lighten,
  useTheme,
} from '@joggrdocs/riker';
import * as hookz from '@react-hookz/web';
import React from 'react';

import {
  GitHubRepositoryMultiSelect,
  type GitHubRepositoryMultiSelectProps,
  useGitHubRepositories,
} from '@stargate/features/github';

export interface GitHubRepositoriesFilterProps {
  onChange: (repositoryIds: string[]) => void;
}

export const GitHubRepositoriesFilter: React.FC<
  GitHubRepositoriesFilterProps
> = ({ onChange }) => {
  const ghRepos = useGitHubRepositories();
  const theme = useTheme();

  const handleChange = React.useCallback<
    GitHubRepositoryMultiSelectProps['onChange']
  >(
    (repositories) => {
      console.log(repositories);
      onChange(repositories.map((repo) => repo.id.toString()));
    },
    [onChange]
  );

  hookz.useMountEffect(() => {
    if (ghRepos.data.length === 0) {
      ghRepos.refresh();
    }
  });

  return (
    <GitHubRepositoryMultiSelect
      id='github-repositories-filter'
      options={ghRepos.data}
      onChange={handleChange}
      label='Filter by GitHub Repositories'
      hideIcon
      size='small'
      sx={{
        [`& .${formLabelClasses.root}.${inputLabelClasses.root}:not(.Mui-focused)`]:
          {
            color: theme.utils.modeValue({
              light: lighten(theme.palette.text.primary, 0.5),
              dark: darken(theme.palette.text.primary, 0.4),
            }),
          },
      }}
      fullWidth
    />
  );
};

export default GitHubRepositoriesFilter;
