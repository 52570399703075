import config, { type Config } from '@stargate/config';
import { debugSession } from '@stargate/lib/debug-session';
import _ from 'lodash';
import pino from 'pino';

/**
 * Create a development logger, that logs to the console in non-production environments.
 *
 * @param config A configuration object.
 * @param prefix A prefix to add to all messages.
 * @returns A logger object with debug, info, warn, error, and fatal methods.
 */
export const createDevLogger = (config: Config, prefix?: string) => {
  return {
    debug: createDevLoggerFunc(config, pino.levels.values.debug, prefix),
    info: createDevLoggerFunc(config, pino.levels.values.info, prefix),
    warn: createDevLoggerFunc(config, pino.levels.values.warn, prefix),
    error: createDevLoggerFunc(config, pino.levels.values.error, prefix),
    fatal: createDevLoggerFunc(config, pino.levels.values.fatal, prefix),
  };
};

export type DevLoggerPayload = {
  message?: string;
  data?: object;
  [key: string]: unknown;
};

/**
 * Create a development logger function for a specific log level.
 *
 * @param config A configuration object.
 * @param level A log level.
 * @param prefix A prefix to add to all messages.
 * @returns A logger function.
 */
export const createDevLoggerFunc = (
  config: Config,
  level: number,
  prefix?: string
) => {
  return (arg1: DevLoggerPayload | string, arg2?: string): void => {
    if (config.environment !== 'production') {
      const { message: logDataMessage, ...logData } =
        typeof arg1 === 'string' ? {} : arg1;
      const message =
        typeof arg1 === 'string'
          ? arg1
          : logDataMessage ?? arg2 ?? 'message not provided';
      const levelLabel = pino.levels.labels[level].toUpperCase();
      const ts = `[${new Date().toISOString()}]`;
      const pre = prefix ? `<${prefix}>` : '';
      const msgData = [DEV_LOGGER_FILTER, ts, levelLabel, pre].join(' ');
      const msg = `${msgData.trim()}: ${message}`;
      const data = {
        ...logData,
        __debug: {
          sessionId: debugSession.getId(),
          level: levelLabel,
        },
      };

      if (level >= pino.levels.values.error) {
        console.error(..._.compact([msg, data]));
      } else {
        console.log(..._.compact([msg, data]));
      }
    }
  };
};

/**
 * The filter to use for development logs.
 */
export const DEV_LOGGER_FILTER = '<jgr>';

/**
 * Pre-configured development logger.
 */
export const devLogger = createDevLogger(config);
