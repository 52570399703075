import {
  Box,
  Chip,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  RikerIcon,
  Skeleton,
  Stack,
  Tooltip,
} from '@joggrdocs/riker';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';

import { useAPIClient } from '@stargate/api';
import { jdocCodeSourcesQueryOptions } from '@stargate/api';
import { DeleteIconButton } from '@stargate/components/Buttons/components/DeleteIconButton';
import { DialogDeleteConfirmation } from '@stargate/components/Dialogs';
import { FilePathTruncate } from '@stargate/components/Utils';
import {
  CodeLinkChip,
  CodeLinkIcon,
  useCodeExplorer,
} from '@stargate/features/code';
import type {
  JDocCodeSource,
  JDocComponentProps,
  JDocMode,
} from '@stargate/features/docs';
import {
  GitHubRepositoryIcon,
  useGitHubRepositories,
} from '@stargate/features/github';
import { useNotify } from '@stargate/lib/notify';
import { useLocalization } from '@stargate/localization';
import { generateComponentClasses } from '@stargate/theme';

import { useJDocDraftMutate } from '../hooks/use-jdoc-draft-mutate';
import { filterCodeLinks } from '../utils';

export const joggrDocCodeLinksClasses = generateComponentClasses(
  'JoggrDocCodeLinks',
  ['root', 'chip'] as const
);

export type JoggrDocCodeLinksProps = JDocComponentProps;

export const JoggrDocCodeLinks: React.FC<JoggrDocCodeLinksProps> = ({
  doc,
  draft,
  mode,
}) => {
  const apiClient = useAPIClient();
  const queryClient = useQueryClient();
  const notify = useNotify();
  const localz = useLocalization();
  const jdocDraft = useJDocDraftMutate();
  const ghRepos = useGitHubRepositories();
  const [, codeExplorerActions] = useCodeExplorer();

  /*
  |------------------
  | Queries & Mutations
  |------------------
  */

  const readQuery = useQuery({
    ...jdocCodeSourcesQueryOptions(doc?.id),
    initialData: [],
  });
  const deleteMutation = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: jdocCodeSourcesQueryOptions(doc?.id).queryKey,
      });
    },
    mutationFn: async ({ codeSourceId }: { codeSourceId: string }) => {
      return await apiClient.request('DELETE /code-sources/:codeSourceId', {
        params: {
          codeSourceId,
        },
      });
    },
  });

  /*
  |------------------
  | State Management
  |------------------
  */

  const [deleteCodeLinkId, setDeleteCodeLinkId] = React.useState<null | string>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  /*
  |------------------
  | Computed
  |------------------
  */

  const codeSources = React.useMemo(() => {
    if (mode === 'create') {
      return draft?.codeSources ?? [];
    }
    return readQuery.data;
  }, [readQuery.data, draft?.codeSources, mode]);

  const codeLinks = React.useMemo(
    () => filterCodeLinks(codeSources),
    [codeSources]
  );

  const loading = React.useMemo(() => {
    return ghRepos.loading || readQuery.isFetching || readQuery.isPending;
  }, [ghRepos.loading, readQuery.isFetching, readQuery.isPending]);

  const open = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const disallowCreation = React.useMemo(() => {
    return _.every([!_.isNil(doc), doc?.version !== 'latest']);
  }, [doc]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const openCodeLink = (codeLink: JDocCodeSource): void => {
    setAnchorEl(null);
    codeExplorerActions.mergeQuery({
      githubOrganizationId: codeLink.repositoryOwnerId,
      githubRepositoryId: codeLink.repositoryId,
      branch: codeLink.branchName,
      code: {
        path: codeLink.path,
      },
    });
    codeExplorerActions.open();
  };

  const handleDelete = async (): Promise<void> => {
    try {
      if (_.isNil(deleteCodeLinkId)) {
        return;
      }
      await deleteMutation.mutateAsync({
        codeSourceId: deleteCodeLinkId,
      });
      jdocDraft.removeCodeSource(deleteCodeLinkId);
      notify.success('Code Link successfully removed!');
      setDeleteCodeLinkId(null);
      setAnchorEl(null);
    } catch (error) {
      notify.error((error as Error).message);
      setDeleteCodeLinkId(null);
      setAnchorEl(null);
    }
  };

  if (loading) {
    return <CodeLinksSkeleton />;
  }

  return (
    <React.Fragment>
      <Box className={joggrDocCodeLinksClasses.root}>
        <CodeLinkChip
          id='code-links-button'
          tabIndex={-1}
          className={joggrDocCodeLinksClasses.chip}
          onClick={(e) => {
            if (codeLinks.length > 0) {
              setAnchorEl(e.currentTarget);
            } else if (mode !== 'view') {
              codeExplorerActions.open();
            }
          }}
          label={localz.formatMessage('features.docs.code-links.chip.label', {
            numLinks: codeLinks.length,
          })}
          aria-haspopup='true'
          aria-controls={open ? 'code-links-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          clickable={codeLinks.length > 0 || mode !== 'view'}
        />
        {codeLinks.length > 0 && (
          <Menu
            id='code-links-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={() => {
              setAnchorEl(null);
            }}
            MenuListProps={{
              'aria-labelledby': 'code-links-button',
            }}
          >
            {codeLinks.map((codeLink) => {
              const repository = ghRepos.data.find(
                ({ id }) => id.toString() === codeLink.repositoryId.toString()
              );
              return (
                <MenuItem
                  key={codeLink.path}
                  disableRipple
                  disableTouchRipple
                  onClick={() => {
                    openCodeLink(codeLink);
                  }}
                >
                  <ListItemIcon>
                    <CodeLinkIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <code style={{ width: 'fit-content' }}>
                      <FilePathTruncate
                        filePath={codeLink.path}
                        maxCharacters={40}
                        disableHint
                      />
                    </code>
                  </ListItemText>
                  <Box sx={{ flexGrow: 1, width: '24px', mr: 2 }} />
                  <Stack direction='row' alignItems='center' spacing={1}>
                    <Chip
                      icon={<GitHubRepositoryIcon />}
                      label={repository?.name}
                      size='small'
                    />
                    <Divider orientation='vertical' flexItem />
                    <Tooltip
                      title={localz.formatMessage(
                        'features.docs.code-links.action.view.tooltip'
                      )}
                      placement='bottom'
                    >
                      <IconButton
                        size='small'
                        onClick={(e) => {
                          e.stopPropagation();
                          openCodeLink(codeLink);
                        }}
                      >
                        <RikerIcon name='file-code-2' size={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={localz.formatMessage(
                        `features.docs.${mode}.code-links.action.delete.tooltip`
                      )}
                      placement='bottom'
                    >
                      <span>
                        <DeleteIconButton
                          size='small'
                          disabled={mode === 'view'}
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteCodeLinkId(codeLink.id);
                          }}
                        />
                      </span>
                    </Tooltip>
                  </Stack>
                </MenuItem>
              );
            })}
            {mode !== 'view' &&
              disallowCreation === false && [
                <Divider key='first' />,
                <MenuItem
                  key='second'
                  onClick={() => {
                    codeExplorerActions.open();
                    setAnchorEl(null);
                    setDeleteCodeLinkId(null);
                  }}
                >
                  <Stack
                    direction='row'
                    alignItems='center'
                    spacing={0}
                    sx={{
                      ml: 'auto',
                      mr: 'auto',
                    }}
                  >
                    <ListItemIcon>
                      <RikerIcon name='link-plus' />
                    </ListItemIcon>
                    <ListItemText>Create a new code link</ListItemText>
                  </Stack>
                </MenuItem>,
              ]}
          </Menu>
        )}
      </Box>
      <DialogDeleteConfirmation
        title={localz.formatMessage(
          'features.docs.code-links.delete.dialog.title'
        )}
        question={localz.formatMessage(
          'features.docs.code-links.delete.dialog.question',
          {
            filePath: codeLinks.find(({ id }) => id === deleteCodeLinkId)?.path,
            code: (chunks) => <code>{chunks}</code>,
          }
        )}
        open={deleteCodeLinkId !== null}
        onClose={() => setDeleteCodeLinkId(null)}
        onDelete={handleDelete}
      />
    </React.Fragment>
  );
};

/*
|------------------
| Utils
|------------------
*/

const CodeLinksSkeleton: React.FC = () => {
  return (
    <Skeleton>
      <CodeLinkChip
        className={joggrDocCodeLinksClasses.chip}
        label='Loading...'
        disabled
      />
    </Skeleton>
  );
};
