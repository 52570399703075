import {
  type AppState as Auth0AppState,
  Auth0Provider as BaseAuth0Provider,
} from '@auth0/auth0-react';
import { usePrevious } from '@react-hookz/web';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useConfig } from '@stargate/hooks';

import { useDevLogger } from '@stargate/logger';
import { useAuth0 } from '../hooks/useAuth0';
import { authToken } from '../lib/auth-token';

/**
 * A pre-configured Auth0 provider, with a built-in redirect callback.
 */
export const Auth0Provider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const navigate = useNavigate();
  const config = useConfig();

  const onRedirectCallback = (appState?: Auth0AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <BaseAuth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={config.auth0.url}
      clientId={config.auth0.clientId}
      authorizationParams={{
        audience: config.auth0.audience,
        redirect_uri: `${window.location.origin}/authorize`,
      }}
    >
      <Auth0ProviderInner>{children}</Auth0ProviderInner>
    </BaseAuth0Provider>
  );
};

/**
 * Component used to handle caching the Auth0 token for API requests.
 */
const Auth0ProviderInner: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const location = useLocation();
  const auth0 = useAuth0();
  const devLogger = useDevLogger();

  const previous = usePrevious(location);
  const changed = React.useMemo(() => {
    if (previous === undefined) {
      return false;
    }

    return previous.pathname !== location.pathname;
  }, [location, previous]);

  const refreshToken = React.useCallback(async () => {
    const token = await auth0.getAccessTokenSilently();
    authToken.write(token);
    devLogger.debug('Refreshed Auth0 token');
  }, [auth0, devLogger]);

  React.useEffect(() => {
    if (changed && authToken.isExpired() && auth0.isAuthenticated) {
      void refreshToken();
    }
  }, [changed, refreshToken, auth0.isAuthenticated]);

  return children;
};
