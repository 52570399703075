import * as Sentry from '@sentry/react';
import logger from '@stargate/lib/logger';
import { LocalStorage } from '@stargate/lib/storage';

/*
|==========================================================================
| AuthToken
|==========================================================================
|
| Read/write the access tokens to Browser Storage.
|
*/

/**
 * A token returned by Auth0.
 */
export interface Auth0Token {
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  permissions: string[];
}

/**
 * AuthToken library, used to read/write the access tokens to Browser Storage.
 */
export const authToken = {
  /**
   * Get the access token from browser storage.
   *
   * @returns The access token
   */
  read() {
    const token = LocalStorage.get<string>('auth0:token');

    if (!token) {
      logger.warn(
        "No token found, you may need to login OR you aren't calling useAuth0 in the App.tsx."
      );
    }

    return token;
  },

  /**
   * Set the access token in browser storage.
   *
   * @param token A valid access token
   */
  write(token: string) {
    LocalStorage.set('auth0:token', token);
  },

  /**
   * Parse an access token.
   *
   * @param token A valid access token
   * @returns An object representing the token
   */
  parse(token: string): Auth0Token | null {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (err) {
      logger.error('Failed to parse token');
      Sentry.captureException(err, {
        extra: {
          message: 'Failed to parse token',
        },
      });
      return null;
    }
  },

  /**
   * Check if the access token is expired.
   *
   * @returns True if the token is expired, false otherwise
   */
  isExpired() {
    const token = this.read();

    if (!token) {
      return true;
    }

    const parsedToken = this.parse(token);
    if (!parsedToken) {
      return true;
    }

    return Date.now() / 1000 >= parsedToken.exp;
  },
};
