import { produce } from 'immer';
import React from 'react';
import { create } from 'zustand';

import { ErrorGuard } from '@stargate/components/Guards';
import { LoadingOverlay } from '@stargate/components/Loading';
import { Confetti } from '@stargate/components/Utils';
import { useLogger } from '@stargate/logger';
import { Outlet, useNavigate } from '@stargate/routes';
import { useUIState } from '@stargate/stores';
import { useSentry } from '@stargate/vendors/sentry';

/*
|==========================================================================
| App
|==========================================================================
| 
| Top Level Application Component.
|
*/

export const App: React.FC = () => {
  const navigate = useNavigate();
  const sentry = useSentry();
  const logger = useLogger();
  const app = useApp();
  const showConfetti = useUIState((state) => state.showConfetti);

  return (
    <ErrorGuard>
      <LoadingOverlay
        variant='full'
        loading={app.loading}
        timeout={30000}
        onTimeout={() => {
          const err = new Error('App loader timed out');
          sentry.captureException(err);
          logger.error(err.message);
          navigate('root.error');
        }}
      />
      {showConfetti && <Confetti recycle />}
      <Outlet />
    </ErrorGuard>
  );
};

export interface UseAppStateHook extends AppStateStore {}

/**
 * Hook to access the global application state and global application actions.
 */
export const useApp = (): UseAppStateHook => {
  return useAppStateStore((state) => state);
};

/*
|------------------
| Utils
|------------------
*/

interface AppStateStore {
  /**
   * Global loading state for the application.
   */
  loading: boolean;

  /**
   * Set the global loading state for the application.
   *
   * @param loading A boolean value to set the loading state.
   */
  setLoading: (loading: boolean) => void;

  /**
   * Global error state for the application.
   */
  error: Error | null;

  /**
   * Set the global error state for the application.
   *
   * @param error An error object to set.
   */
  setError: (error: Error | null) => void;
}

const useAppStateStore = create<AppStateStore>((set) => ({
  error: null,
  loading: true,
  setError: (error) => {
    set(
      produce((draft) => {
        draft.error = error;
      })
    );
  },
  setLoading: (loading) => {
    set(
      produce((draft) => {
        draft.loading = loading;
      })
    );
  },
}));
