import { Skeleton } from '@joggrdocs/riker';
import { usePrevious } from '@react-hookz/web';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import React from 'react';
import type * as TF from 'type-fest';

import {
  type APIResponse,
  type JDocContentQueryResponse,
  type JDocCreateMutationPayload,
  type JDocUpdateMutationPayload,
  jdocContentQueryOptions,
  jdocCreateMutationFn,
  jdocQueryOptions,
  jdocUpdateMutationFn,
  useJDocDeleteMutation,
  useJDocUpdateCodeSourcesMutation,
  useJDocUpdateTagsMutation,
} from '@stargate/api';
import { Page } from '@stargate/components/Utils';
import { useDashDraftEditor } from '@stargate/dashdraft';
import { CodeExplorer } from '@stargate/features/code';
import { useDirectoryTree } from '@stargate/features/directories';
import {
  type JDoc,
  type JDocCommit,
  JDocContent,
  type JDocDraftQueryResult,
  type JDocMode,
  JoggrDocActions,
  type JoggrDocActionsProps,
  JoggrDocAuthor,
  JoggrDocBreadcrumbs,
  JoggrDocCodeLinks,
  JoggrDocContentLayout,
  JoggrDocDraftDetails,
  JoggrDocFileLocation,
  JoggrDocLayout,
  JoggrDocLayoutDivider,
  JoggrDocModeSwitch,
  JoggrDocSave,
  type JoggrDocSaveProps,
  JoggrDocShare,
  JoggrDocSummary,
  JoggrDocTableOfContents,
  JoggrDocTitle,
  JoggrDocVersionDetails,
  joggrDocContentLayoutClasses,
  useJDocContext,
  useJDocDraftMutate,
  useJDocDraftQuery,
  useJDocTableOfContents,
  useJdocValidate,
} from '@stargate/features/docs';
import { filterCodeSnippets } from '@stargate/features/docs/utils';
import { useGithubRepository } from '@stargate/features/github';
import { useDelayedState } from '@stargate/hooks';
import { useNightingale } from '@stargate/lib/nightingale';
import { useNotify } from '@stargate/lib/notify';
import { useLocalization } from '@stargate/localization';
import { useNavigate } from '@stargate/routes';
import * as utils from '@stargate/utils';
import { FrigadeTarget } from '@stargate/vendors/frigade';
import { useSentry } from '@stargate/vendors/sentry';

/**
 * Edit or View an existing JoggrDoc.
 */
export const DocPage: React.FC = () => {
  const { mode, docId } = useJDocContext('existing');
  const jdocDraftMutate = useJDocDraftMutate();
  const jdocDraftQuery = useJDocDraftQuery();
  const jdocTableOfContents = useJDocTableOfContents();
  const editor = useDashDraftEditor();
  const nightingale = useNightingale();
  const directoryTree = useDirectoryTree();
  const sentry = useSentry();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const localz = useLocalization();
  const notify = useNotify();

  /*
  |------------------
  | Utils
  |------------------
  */

  /**
   * Clear the editor, resetting the ToC and the content.
   */
  const clearEditor = React.useCallback(() => {
    editor.commands.clearContent(false);
    jdocTableOfContents.clear();
  }, [editor.commands.clearContent, jdocTableOfContents.clear]);

  /**
   * Clear the draft, resetting the content and clearing the draft state.
   */
  const clearDraft = React.useCallback(() => {
    clearEditor();
    jdocDraftMutate.clear();
    queryClient.invalidateQueries({
      queryKey: jdocQueryOptions(docId).queryKey,
    });
  }, [queryClient, clearEditor, jdocDraftMutate.clear, docId]);

  /*
  |------------------
  | Queries & Mutations
  |------------------
  */

  const jdocQuery = useQuery({
    ...jdocQueryOptions(docId),
    // staleTime: utils.getMillisecondsByMinutes(15),
    refetchOnReconnect: 'always',
    refetchOnMount: 'always',
    // refetchOnWindowFocus: true,
    throwOnError: true,
  });

  const jdocContentQuery = useQuery({
    ...jdocContentQueryOptions(docId),
    // staleTime: utils.getMillisecondsByMinutes(15),
    refetchOnReconnect: 'always',
    refetchOnMount: 'always',
    // refetchOnWindowFocus: true,
    throwOnError: true,
    enabled: !_.isNil(docId),
  });
  const contentLoading = useContentLoading(mode, jdocContentQuery);

  const jdocDeleteMutation = useJDocDeleteMutation({
    onSuccess: () => {
      clearEditor();
      void directoryTree.onLoad(); // @todo this should be done via a query invalidation
      notify.success('Document successfully deleted');
      navigate('app.root');
    },
    onError: (error, variables) => {
      notify.error(localz.formatMessage('features.docs.delete.error'));
      sentry.captureException(error, {
        data: {
          payload: variables,
        },
      });
    },
  });
  const jdocUpdateCodeSourcesMutation = useJDocUpdateCodeSourcesMutation();
  const jdocUpdateTagsMutation = useJDocUpdateTagsMutation();

  const jdocCreateMutation = useMutation({
    mutationFn: async (payload: JDocCreateMutationPayload) => {
      const result = await jdocCreateMutationFn(payload);

      // If we have code sources, update (upsert) them
      if (jdocDraftQuery.codeSources) {
        void jdocUpdateCodeSourcesMutation.mutateAsync({
          documentId: result.id,
          codeSourceIds: jdocDraftQuery.codeSources.map(
            (codeSource) => codeSource.id
          ),
        });
      }

      return result;
    },
    onSuccess: (data) => {
      notify.success(
        localz.formatMessage('features.docs.save.success.version')
      );
      void nightingale.capture(['create', 'document'], {
        documentId: data.id,
        baseDocumentId: data.baseDocumentId,
      });
      clearEditor();
      jdocDraftMutate.clear();
      queryClient.invalidateQueries({
        queryKey: jdocQueryOptions(docId).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: jdocContentQueryOptions(docId).queryKey,
      });
      navigate('app.documents.view', {
        params: {
          id: data.id,
        },
      });
    },
    onError: (error, variables) => {
      notify.error(localz.formatMessage('features.docs.save.error'));
      sentry.captureException(error, {
        data: {
          payload: variables,
        },
      });
    },
  });

  const jdocUpdateMutation = useMutation({
    mutationFn: async (payload: JDocUpdateMutationPayload) => {
      const result = await jdocUpdateMutationFn(payload);

      // on edit (for now) we just replace the code snippets
      const codeSnippets = filterCodeSnippets(jdocDraftQuery.codeSources ?? []);

      if (codeSnippets.length > 0) {
        await jdocUpdateCodeSourcesMutation.mutateAsync({
          documentId: result.id,
          codeSourceIds: codeSnippets.map((codeSnippet) => codeSnippet.id),
        });
      }

      return result;
    },
    onSuccess: (data) => {
      notify.success(localz.formatMessage('features.docs.save.success.edit'));
      void nightingale.capture(['update', 'document'], {
        documentId: data.id,
      });
      clearEditor();
      queryClient.invalidateQueries({
        queryKey: jdocQueryOptions(docId).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: jdocContentQueryOptions(docId).queryKey,
      });
      jdocDraftMutate.clear();
      directoryTree.onLoad();
    },
    onError: (error, variables) => {
      notify.error(localz.formatMessage('features.docs.save.error'));
      sentry.captureException(error, {
        data: {
          payload: variables,
        },
      });
    },
  });

  /*
  |------------------
  | Computed
  |------------------
  */

  const doc = useJDocCombine(jdocQuery.data, jdocContentQuery.data);
  const draft = useMergeDraftDoc(jdocDraftQuery, doc);

  const jdocValid = useJdocValidate({
    doc: doc,
    draft: draft.doc,
    mode,
  });

  /*
  |------------------
  | Callbacks
  |------------------
  */

  /**
   * Save the JoggrDoc, handles switching between creating a new version or updating the existing document, based
   * on the commit data.
   */
  const handleSave = React.useCallback<JoggrDocSaveProps['onSave']>(
    async (commit) => {
      sentry.addBreadcrumb({
        category: 'jdoc.existing.save',
        data: {
          documentId: docId,
          commit,
        },
      });

      try {
        if (!isValidCommit(commit)) {
          notify.error(
            localz.formatMessage('features.docs.save.error.invalid-commit')
          );
          return;
        }

        const validDraft = jdocValid.validateCurrentDraft();
        if (!validDraft.valid) {
          const missingData = validDraft.missing
            .map((field) => {
              return localz.formatMessage(
                `features.docs.save.error.missing.${field}`
              );
            })
            .join(', ');

          notify.error(
            localz.formatMessage('features.docs.save.error.missing', {
              missingData,
            })
          );
          return;
        }

        if (!_.isNil(commit.baseDocId)) {
          sentry.addBreadcrumb({
            category: 'jdoc.existing.version',
            data: {
              documentId: docId,
              commit,
            },
          });
          await jdocCreateMutation.mutateAsync({
            data: {
              baseDocumentId: commit.baseDocId,
              title: validDraft.draft.title,
              summary: validDraft.draft.summary,
              content: validDraft.draft.content,
              filePath: validDraft.draft.github.filePath,
              branchName: commit.branch,
              repositoryId: validDraft.draft.github.repository.id.toString(),
              repositoryOwnerId:
                validDraft.draft.github.repository.owner.id.toString(),
              message: commit.message,
              sha: commit.sha,
              draftPullRequest: commit.pullRequest === 'draft',
              createPullRequest: commit.pullRequest !== 'none',
            },
          });
        } else {
          sentry.addBreadcrumb({
            category: 'jdoc.existing.version',
            data: {
              documentId: docId,
              commit,
            },
          });
          await jdocUpdateMutation.mutateAsync({
            documentId: docId,
            data: {
              title: validDraft.draft.title,
              summary: validDraft.draft.summary,
              content: validDraft.draft.content,
              filePath: validDraft.draft.github.filePath,
              message: commit.message,
              sha: commit.sha,
            },
          });
        }
      } catch (error) {
        const ex = utils.getErrorSafely(
          error,
          'Unknown error when saving JoggrDoc'
        );
        notify.error(ex.message);
      }
    },
    [
      notify,
      localz.formatMessage,
      docId,
      jdocValid.validateCurrentDraft,
      jdocUpdateMutation.mutateAsync,
      jdocCreateMutation.mutateAsync,
      sentry,
    ]
  );

  /**
   * Handle the actions from the JoggrDocActions component.
   */
  const handleAction = React.useCallback<JoggrDocActionsProps['onAction']>(
    async (payload) => {
      if (payload.action === 'revert') {
        clearDraft();
      } else if (payload.action === 'tags') {
        jdocDraftMutate.updateTags(payload.data);
        await jdocUpdateTagsMutation.mutateAsync({
          documentId: docId,
          tags: payload.data.map((tag) => tag.id),
        });
      } else if (payload.action === 'delete') {
        await jdocDeleteMutation.mutateAsync({
          documentId: docId,
        });
      }
    },
    [
      clearDraft,
      docId,
      jdocDeleteMutation,
      jdocDraftMutate.updateTags,
      jdocUpdateTagsMutation,
    ]
  );

  /*
  |------------------
  | Effects
  |------------------
  */

  // @todo handle via tanstack/router
  const previousDocId = usePrevious(docId);
  // biome-ignore lint/correctness/useExhaustiveDependencies: Only care if the docId changes
  React.useEffect(() => {
    if (docId !== previousDocId && !_.isNil(docId)) {
      // Invalidate the queries
      // queryClient.invalidateQueries({
      //   queryKey: jdocQueryOptions(docId).queryKey,
      // });
      // queryClient.invalidateQueries({
      //   queryKey: jdocContentQueryOptions(docId).queryKey,
      // });

      // Log the view event
      nightingale.capture(['view', 'document'], {
        documentId: docId,
      });
    }
  }, [docId, previousDocId]);

  return (
    <Page
      id={`${mode}-document`}
      title={jdocQuery.data?.title ?? `${_.capitalize(mode)} JoggrDoc`}
      loading={jdocQuery.isFetching || jdocQuery.isPending}
      errors={[jdocQuery.error, jdocContentQuery.error]}
      renderProps={{
        doc,
        draft,
        mode,
      }}
      gutter={false}
      render={({ doc, draft, mode }) => {
        return (
          <React.Fragment>
            <JoggrDocLayout
              loading={jdocContentQuery.status === 'pending'}
              metadata={
                <React.Fragment>
                  <FrigadeTarget targetId='jdoc-file-location'>
                    <JoggrDocFileLocation doc={doc} draft={draft} mode={mode} />
                  </FrigadeTarget>
                  <JoggrDocLayoutDivider gutter={0} />
                  <FrigadeTarget targetId='jdoc-version-details'>
                    <JoggrDocVersionDetails
                      doc={doc}
                      draft={draft}
                      mode={mode}
                    />
                  </FrigadeTarget>
                  {mode === 'edit' && jdocValid.modifiedFields.length > 0 && (
                    <JoggrDocLayoutDivider gutter={0} />
                  )}
                  <JoggrDocDraftDetails
                    doc={doc}
                    draft={draft}
                    mode={mode}
                    modifiedFields={jdocValid.modifiedFields}
                  />
                </React.Fragment>
              }
              actions={
                <React.Fragment>
                  <FrigadeTarget targetId='jdoc-mode-switch'>
                    <JoggrDocModeSwitch doc={doc} draft={draft} mode={mode} />
                  </FrigadeTarget>
                  <JoggrDocLayoutDivider />
                  {mode === 'edit' && (
                    <React.Fragment>
                      {jdocContentQuery.status === 'pending' ? (
                        <Skeleton variant='rounded' animation='wave'>
                          <JoggrDocSave
                            doc={doc}
                            draft={draft}
                            modifiedFields={jdocValid.modifiedFields}
                            missingFields={jdocValid.missingFields}
                            mode={mode}
                            onSave={handleSave}
                          />
                        </Skeleton>
                      ) : (
                        <FrigadeTarget targetId='jdoc-save'>
                          <JoggrDocSave
                            doc={doc}
                            draft={draft}
                            modifiedFields={jdocValid.modifiedFields}
                            missingFields={jdocValid.missingFields}
                            mode={mode}
                            onSave={handleSave}
                          />
                        </FrigadeTarget>
                      )}
                    </React.Fragment>
                  )}
                  {mode === 'view' && (
                    <FrigadeTarget targetId='jdoc-share'>
                      <JoggrDocShare docId={doc.id} />
                    </FrigadeTarget>
                  )}
                  <FrigadeTarget targetId='jdoc-actions'>
                    <JoggrDocActions
                      doc={doc}
                      draft={draft}
                      mode={mode}
                      loading={false}
                      modified={jdocValid.modifiedFields.length > 0}
                      onAction={handleAction}
                    />
                  </FrigadeTarget>
                </React.Fragment>
              }
              content={
                <JoggrDocContentLayout
                  title={<JoggrDocTitle doc={doc} draft={draft} mode={mode} />}
                  summary={
                    <JoggrDocSummary doc={doc} draft={draft} mode={mode} />
                  }
                  metadata={
                    <React.Fragment>
                      <FrigadeTarget targetId='jdoc-author'>
                        <JoggrDocAuthor author={doc.author} />
                      </FrigadeTarget>
                      <JoggrDocLayoutDivider />
                      <FrigadeTarget targetId='jdoc-breadcrumbs'>
                        <JoggrDocBreadcrumbs
                          doc={doc}
                          draft={draft}
                          mode={mode}
                        />
                      </FrigadeTarget>
                      <JoggrDocLayoutDivider />
                      <FrigadeTarget targetId='jdoc-code-links'>
                        <JoggrDocCodeLinks
                          doc={doc}
                          draft={draft}
                          mode={mode}
                        />
                      </FrigadeTarget>
                    </React.Fragment>
                  }
                  content={
                    <JDocContent
                      doc={doc}
                      draft={draft}
                      mode={mode}
                      loading={contentLoading}
                    />
                  }
                  onClick={(env, section) => {
                    if (
                      section === 'content' &&
                      !editor.isFocused &&
                      // @ts-expect-error - valid check
                      env.target.classList?.contains(
                        joggrDocContentLayoutClasses.content
                      )
                    ) {
                      editor.commands.focus();
                    }
                  }}
                />
              }
            />
            <JoggrDocTableOfContents
              doc={doc}
              draft={draft}
              mode={mode}
              loading={contentLoading}
            />
            <CodeExplorer
              doc={doc}
              draft={draft}
              mode={mode}
              readonly={mode === 'view'}
            />
          </React.Fragment>
        );
      }}
    />
  );
};

/*
|------------------
| Utils
|------------------
*/

const useMergeDraftDoc = (
  draft: JDocDraftQueryResult | null,
  doc: JDoc | null
) => {
  return React.useMemo(() => {
    return {
      ...draft,
      doc: {
        ...doc,
        ...draft?.doc,
        title: draft?.doc?.title ?? doc?.title,
        summary: draft?.doc?.summary ?? doc?.summary,
        content: draft?.doc?.content ?? doc?.content,
        github: {
          filePath: draft?.doc?.github?.filePath ?? doc?.github?.filePath,
          branch: draft?.doc?.github?.branch ?? doc?.github?.branch,
          sha: draft?.doc?.github?.sha ?? doc?.github?.sha,
          pullRequest:
            draft?.doc?.github?.pullRequest ?? doc?.github?.pullRequest,
          repository: draft?.doc?.github?.repository ?? doc?.github?.repository,
        },
      },
    };
  }, [draft, doc]);
};

/**
 * Build the JoggrDoc record from the API response, content and other metadata.
 *
 * @param docResponse Get the JoggrDoc data.
 * @param contentResponse Get the JoggrDoc content data.
 * @returns A JoggrDoc object.
 */
const useJDocCombine = (
  docResponse?: APIResponse<'GET /documents/:documentId'> | null,
  contentResponse?: JDocContentQueryResponse | null
): JDoc | null => {
  const githubRepository = useGithubRepository(
    docResponse?.repositoryId.toString()
  );

  return React.useMemo(() => {
    if (!docResponse || !contentResponse || !githubRepository.data) {
      return null;
    }

    return {
      id: docResponse.id,
      createdAt: docResponse.createdAt,
      updatedAt: docResponse.updatedAt,
      title: docResponse.title,
      summary: docResponse.summary,
      content: contentResponse.content,
      author: docResponse.author,
      baseDocumentId: docResponse.baseDocumentId,
      version: docResponse.version,
      github: {
        sha: docResponse.sha ?? contentResponse.sha,
        branch: docResponse.branchName,
        filePath: docResponse.filePath,
        pullRequest: docResponse.pullRequest,
        repository: githubRepository.data,
      },
    };
  }, [docResponse, contentResponse, githubRepository]);
};

type ValidJDocCommit = TF.SetRequired<JDocCommit, 'sha'>;

/**
 * Check if a JoggrDocCommit is valid.
 *
 * @param commit A JoggrDocCommit.
 * @returns A boolean indicating whether the commit is valid.
 */
const isValidCommit = (commit: JDocCommit): commit is ValidJDocCommit => {
  return _.isString(commit.sha);
};

/**
 * Get the content loading state, based on the query and the mode.
 * We abstract this away because we are having to handle switching between the different modes, we should in stead use
 * the router to handle this, by switching between different routes & handling onMount/unMount
 */
const useContentLoading = (
  mode: JDocMode,
  contentQuery: ReturnType<typeof useQuery>
): boolean => {
  const [loading, setLoading, setLoadingDelayed] = useDelayedState(false);

  // If the mode changes, we have to update the editor content
  // based on the combined doc or draft content.
  const previousMode = usePrevious(mode);
  // biome-ignore lint/correctness/useExhaustiveDependencies: ignore state
  React.useEffect(() => {
    if (mode !== previousMode && !!previousMode) {
      setLoading(true);
      setLoadingDelayed(false, 250);
    }
  }, [mode, previousMode]);

  return React.useMemo(() => {
    return _.some([
      contentQuery.isPending,
      contentQuery.isFetching,
      contentQuery.isLoading,
      !contentQuery.data,
      loading,
    ]);
  }, [
    loading,
    contentQuery.isPending,
    contentQuery.isFetching,
    contentQuery.isLoading,
    contentQuery.data,
  ]);
};
