import { useAuth0 as useBaseAuth0 } from '@auth0/auth0-react';

/*
|==========================================================================
| useAuth0
|==========================================================================
|
| Thin wrapper around the Auth0 useAuth0 hook that allows us to add functionality & 
| override certain methods as needed.
|
*/

/**
 * The `useAuth0` hook to be used in your components to access the auth state and methods.
 *
 * Example output:
 * ```js
 * const {
 *   // Auth state:
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   user,
 *   // Auth methods:
 *   getAccessTokenSilently,
 *   getAccessTokenWithPopup,
 *   getIdTokenClaims,
 *   loginWithRedirect,
 *   loginWithPopup,
 *   logout,
 * } = useAuth0<TUser>();
 * ```
 */
export function useAuth0() {
  return useBaseAuth0();
}
