import { queryOptions } from '@tanstack/react-query';
import _ from 'lodash';

import type { DashDraftJSONContent } from '@/lib/dashdraft';
import type * as Typist from '@/lib/typist';
// import * as utils from '@stargate/utils';

import { apiClient } from '@/api/api-client';
import { transformFromAPIContent } from '@/api/lib/transformers';
import type { APIResponse } from '@/api/types';

/*
|==========================================================================
| query-options
|==========================================================================
|
| Query options for JoggrDocs, including fetching data for a JoggrDoc & related data.
|
*/

/**
 * Get the query options for a JoggrDoc.
 *
 * @api `GET /documents/:documentId`
 * @param docId The ID of the JoggrDoc.
 * @returns The query options.
 */
export const jdocQueryOptions = (docId?: string | null) => {
  return queryOptions({
    queryKey: ['GET /documents/:documentId', docId] as const,
    queryFn: async ({ queryKey }) => {
      const [route, docId] = queryKey;

      if (!_.isNil(docId)) {
        return apiClient.request(route, {
          params: {
            documentId: docId,
          },
        });
      }

      return null;
    },
    retry: false,
    // staleTime: utils.getMillisecondsByMinutes(15),
    // refetchOnWindowFocus: true,
  });
};

/**
 * The content of a JoggrDoc.
 */
export type JDocContentQueryResponse = Typist.Simplify<
  Omit<
    Typist.SchemaStrict<APIResponse<'GET /documents/:documentId/content'>>,
    'content' | 'format'
  > & {
    content: DashDraftJSONContent;
    format: 'json';
  }
>;

/**
 * Get the query options for a JoggrDoc's content.
 *
 * @api `GET /documents/:documentId/content`
 * @param docId The ID of the JoggrDoc.
 * @returns The query options.
 */
export const jdocContentQueryOptions = (docId?: string | null) => {
  return queryOptions({
    queryKey: ['GET /documents/:documentId/content', docId] as const,
    queryFn: async ({ queryKey }) => {
      const [route, docId] = queryKey;

      if (!_.isNil(docId)) {
        const result = await apiClient.request(route, {
          params: { documentId: docId },
        });
        return {
          ...result,
          content: await transformFromAPIContent(result.content),
          format: 'json',
        } satisfies JDocContentQueryResponse;
      }

      return null;
    },
    retry: false,
    // staleTime: utils.getMillisecondsByMinutes(15),
    // refetchOnWindowFocus: true,
  });
};

/**
 * Get the query options for a JoggrDoc's tags.
 *
 * @api `GET /documents/:documentId/tags`
 * @param docId The ID of the JoggrDoc.
 * @returns The query options.
 */
export const jdocTagsQueryOptions = (docId?: string | null) => {
  return queryOptions({
    queryKey: ['GET /documents/:documentId/tags', docId] as const,
    queryFn: async ({ queryKey }) => {
      const [route, docId] = queryKey;
      if (!_.isNil(docId)) {
        return apiClient.request(route, {
          params: { documentId: docId },
        });
      }

      return [];
    },
    // staleTime: utils.getMillisecondsByMinutes(15),
    // refetchOnWindowFocus: true,
  });
};

/**
 * Get the query options for a JoggrDoc's code sources.
 *
 * @api `GET /documents/:documentId/code-sources`
 * @param docId The ID of the JoggrDoc.
 * @returns The query options.
 */
export const jdocCodeSourcesQueryOptions = (docId?: string | null) => {
  return queryOptions({
    queryKey: ['GET /documents/:documentId/code-sources', docId] as const,
    queryFn: async ({ queryKey }) => {
      const [route, docId] = queryKey;
      if (!_.isNil(docId)) {
        const result = await apiClient.request(route, {
          params: { documentId: docId },
        });

        return result;
      }

      return [];
    },
    // staleTime: utils.getMillisecondsByMinutes(15),
    // refetchOnWindowFocus: true,
  });
};

/**
 * Get the query options for a JoggrDoc's ancestors.
 *
 * @api `GET /documents/:documentId/ancestors`
 * @param docId The ID of the JoggrDoc.
 * @returns The query options.
 */
export const jdocAncestorsQueryOptions = (docId?: string | null) => {
  return queryOptions({
    queryKey: ['GET /documents/:documentId/ancestors', docId] as const,
    queryFn: async ({ queryKey }) => {
      const [route, docId] = queryKey;

      if (!_.isNil(docId)) {
        return apiClient.request(route, {
          params: { documentId: docId },
        });
      }

      return [];
    },
    // staleTime: utils.getMillisecondsByMinutes(15),
    // refetchOnWindowFocus: true,
  });
};

/**
 * Get the query options for a JoggrDoc's versions.
 *
 * @api `GET /documents/:documentId/versions`
 * @param docId A JoggrDoc ID.
 * @returns The query options.
 */
export const jdocVersionsQueryOptions = (docId?: string | null) => {
  return queryOptions({
    queryKey: ['GET /documents/:documentId/versions', docId] as const,
    queryFn: async ({ queryKey }) => {
      const [route, docId] = queryKey;

      if (!_.isNil(docId)) {
        return apiClient.request(route, {
          params: { documentId: docId },
        });
      }

      return [];
    },
    // staleTime: utils.getMillisecondsByMinutes(15),
    // refetchOnWindowFocus: true,
  });
};
