import { fetchAuthorized, useHTTPClient } from '@stargate/lib/http';
import _ from 'lodash';

import {
  buildAPIRequestData,
  buildAPIRequestHeaders,
  buildAPIRequestURL,
  getAPIRequestMethod,
} from '@stargate/api/lib/utils';
import type {
  APIParams,
  APIPayload,
  APIResponse,
  APIRoute,
} from '@stargate/api/types';

/**
 * Create a type-safe & authorized API client for the Joggr API.
 *
 * @param makeFetch A fetch function that is authorized.
 * @returns A type-safe API client for the Joggr API.
 */
const apiClientFactory = (makeFetch: typeof fetchAuthorized) => {
  return {
    async request<R extends APIRoute>(name: R, payload: APIPayload<R>) {
      return await makeFetch<APIResponse<R>>(
        buildAPIRequestURL<R>(
          name,
          _.get(payload, 'params', {}) as APIParams<R>
        ),
        {
          method: getAPIRequestMethod<R>(name),
          data: buildAPIRequestData<R>(payload),
          headers: buildAPIRequestHeaders<R>(payload),
        }
      );
    },
  };
};

/**
 * Create a type-safe API client hook for the Joggr API.
 *
 * @returns A hook with a request method for making requests to the API.
 */
export const useAPIClient = () => {
  const httpClient = useHTTPClient();
  return apiClientFactory(httpClient.fetchAuthorized);
};

/**
 * A type-safe API client (without React) for the Joggr API.
 */
export const apiClient = apiClientFactory(fetchAuthorized);
