import _ from 'lodash';
import qs from 'qs';
import type * as TypeFest from 'type-fest';

import config from '@stargate/config';
import { debugSession } from '@stargate/lib/debug-session';
import * as auth0Lib from '@stargate/vendors/auth0';

import type { HttpErrorCode } from './http-error-codes';
import { createError, isHttpErrorStatus } from './http-errors';

/*
|==========================================================================
| Fetch API
|==========================================================================
|
| Simple typed fetch API that handles authorized requests and JSON.
|
*/

type Body = string | FormData | null;

type BaseHeaders = Record<string, string>;
/*
|------------------
| Public Types
|------------------
*/

export type FetchMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

export type FetchHeaders = TypeFest.Simplify<
  BaseHeaders & {
    Authorization?: string;
    'Content-Type'?: string;

    /**
     * Options to pass to the Joggr HTTP API.
     */
    'x-jgr-options'?: 'clear-cache';

    /**
     * The "debug" session ID used for "tracing" requests.
     */
    'x-jgr-session'?: string;
  }
>;

export type FetchData = Record<string, unknown> | FormData;

export interface FetchOptions {
  method?: FetchMethod;
  headers?: FetchHeaders;
  data?: FetchData;
}

export type FetchResult = unknown;

/**
 * Fetch data from a URL, without authorization.
 *
 * @param url A URL string.
 * @param options A fetch options object.
 * @returns A promise that resolves to the response data.
 */
export async function fetchUnauthorized<Result extends FetchResult>(
  url: string,
  options?: FetchOptions
): Promise<Result> {
  const freshDebugSession = debugSession.refresh();
  const headers: FetchHeaders = {
    ...options?.headers,
    'x-jgr-session': freshDebugSession.sessionId,
  };
  const method = _.defaultTo(options?.method, 'GET');
  let builtUrl = url;

  if (
    !_.isNil(options?.data) &&
    canHaveBody(method) &&
    !isMultiPart(options.data)
  ) {
    headers['Content-Type'] = 'application/json';
  }

  if (!_.isNil(options?.data) && !canHaveBody(method)) {
    builtUrl = `${url}?${qs.stringify(options.data)}`;
  }

  let body: Body = null;
  if (isMultiPart(options?.data)) {
    body = options.data;
  } else if (!_.isNil(options?.data) && canHaveBody(method)) {
    body = JSON.stringify(options.data);
  }

  const response = await fetch(builtUrl, {
    ...options,
    headers,
    body,
  });

  if (isHttpErrorStatus(response.status)) {
    const responseData = await safeParse<{
      message?: string;
      errorCode?: HttpErrorCode;
    }>(response);
    throw createError(
      response.status,
      responseData.errorCode ?? 'UNK_000',
      responseData.message ?? 'Fetch API Error'
    );
  }
  if (response.status === 204) {
    // No content response returns null
    return null as Result;
  }

  return await safeParse<Exclude<Result, undefined>>(response);
}

/**
 * Fetch data from a URL, with authorization.
 *
 * @param url A URL string.
 * @param options A fetch options object.
 * @returns A promise that resolves to the response data.
 */
export async function fetchAuthorized<Result extends FetchResult>(
  url: string,
  options?: FetchOptions
): Promise<Result> {
  const token = auth0Lib.authToken.read();

  if (_.isNil(token)) {
    throw createError(400, 'UNK_000', 'Invalid authorization token.');
  }

  return await fetchUnauthorized<Result>(`${config.api.url}${url}`, {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Bearer ${token}`,
    },
  });
}

/*
|------------------
| Utils
|------------------
*/

/**
 * Check if the data is a FormData object.
 *
 * @param data A data object.
 * @returns A boolean indicating if the data is a FormData object.
 */
const isMultiPart = (data: unknown): data is FormData => {
  return data instanceof FormData;
};

/**
 * Check if a method can have a body.
 *
 * @param method A fetch method.
 * @returns A boolean indicating if the method can have a body.
 */
const canHaveBody = (method: FetchMethod): boolean => {
  return !['GET', 'DELETE'].includes(method);
};

/**
 * (Safely) Parse a response.
 *
 * @param response A fetch response.
 * @returns A promise that resolves to the parsed response.
 */
const safeParse = async <R extends Exclude<FetchResult, undefined>>(
  response: Response
): Promise<R> => {
  try {
    if (response.status === 204) {
      return null as R;
    }

    return (await response.json()) as R;
  } catch (error) {
    // We assume that if we get a 2XX and the response fails to parse, it's a "no content response" or some
    // other non-JSON response that needs to be handled.
    if (response.status >= 200 && response.status < 300) {
      return null as R;
    }
    throw error;
  }
};
