import {
  Box,
  ClickAwayListener,
  Fab,
  Fade,
  IconBooks,
  IconBug,
  IconCircleCheck,
  IconQuestionMark,
  IconSend,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  listItemTextClasses,
  menuItemClasses,
} from '@joggrdocs/riker';
import _ from 'lodash';
import React from 'react';

import { useConfig } from '@stargate/hooks';
import { useDebugSession } from '@stargate/lib/debug-session';

import { DebugId } from './DebugSessionId';

/*
|==========================================================================
| SupportWidget
|==========================================================================
|
| A widget that provides support options to the user.
|
*/

export const SupportWidget: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const debugSession = useDebugSession();
  const config = useConfig();

  /*
  |------------------
  | Computed
  |------------------
  */

  const open = React.useMemo(() => !!anchorEl, [anchorEl]);

  /*
  |------------------
  | Handlers
  |------------------
  */

  const createClickHandler = (action: 'help' | 'bug') => () => {
    if (action === 'help') {
      window.open(
        `mailto:support@joggr.io?subject=${encodeURIComponent('Help request')}`,
        '_blank'
      );
    } else if (action === 'bug') {
      window.open(
        `mailto:support@joggr.io?subject=${encodeURIComponent(
          `Bug (session_id: "${debugSession.id}")`
        )}`,
        '_blank'
      );
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (!open) {
      setAnchorEl(e.currentTarget);
    } else {
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '16px',
        right: '16px',
        zIndex: (theme) => theme.zIndex.appBar + 1,
      }}
    >
      <Fab color='info' onClick={handleToggle} size='medium'>
        <IconQuestionMark />
      </Fab>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='top-end'
        transition
        sx={{
          marginBottom: (theme) => `${theme.spacing(0.5)} !important`,
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps}>
              <MenuList
                elevation={20}
                component={Paper}
                sx={{
                  bgcolor: (theme) => theme.palette.background.paper,
                  [`& .${menuItemClasses.root}`]: {
                    borderBottom: (theme) =>
                      `1px solid ${theme.palette.divider}`,
                  },
                  [`& .${listItemTextClasses.primary}`]: {
                    fontSize: '18px',
                    fontWeight: 600,
                  },
                  [`& .${listItemTextClasses.secondary}`]: {
                    fontSize: '14px',
                  },
                }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 1,
                    mb: 1,
                  }}
                >
                  <Typography variant='h5'>How can we help you?</Typography>
                </Box>
                <MenuItem<'a'>
                  dense
                  component='a'
                  href='https://docs.joggr.io'
                  rel='noopener noreferrer'
                  target='_blank'
                  sx={{
                    borderTop: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <ListItemIcon>
                    <IconBooks />
                  </ListItemIcon>
                  <ListItemText
                    primary='Docs Site'
                    secondary='Search our FAQs, guides & more'
                  />
                </MenuItem>
                <MenuItem onClick={createClickHandler('help')}>
                  <ListItemIcon>
                    <IconSend />
                  </ListItemIcon>
                  <ListItemText
                    primary='Request Help'
                    secondary='Contact our team and get help'
                  />
                </MenuItem>
                <MenuItem onClick={createClickHandler('bug')}>
                  <ListItemIcon>
                    <IconBug />
                  </ListItemIcon>
                  <ListItemText
                    primary='Report a bug'
                    secondary='Something broken? Let us know!'
                  />
                </MenuItem>
                <MenuItem
                  component='a'
                  href='https://status.joggr.io'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ListItemIcon>
                    <IconCircleCheck />
                  </ListItemIcon>
                  <ListItemText
                    primary='Status Page'
                    secondary='Current status of our services'
                  />
                </MenuItem>
                <Box sx={{ mt: 1 }}>
                  <DebugId />
                </Box>
                <Box>
                  <Typography variant='caption' sx={{ mt: 1 }}>
                    version: {config.releaseVersion}
                  </Typography>
                </Box>
              </MenuList>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};
