import _ from 'lodash';
import React from 'react';
import {
  ErrorBoundary,
  type ErrorBoundaryPropsWithFallback,
  type ErrorBoundaryPropsWithRender,
} from 'react-error-boundary';

import { useApp } from '@stargate/app';
import { HttpErrorFeedback } from '@stargate/components/Feedback';
import { isHttpError } from '@stargate/lib/http';
import { useDevLogger, useLogger } from '@stargate/lib/logger';
import { useLocation } from '@stargate/routes';
import { useSentry } from '@stargate/vendors/sentry';

export type ErrorGuardProps =
  | ErrorBoundaryPropsWithRender
  | ErrorBoundaryPropsWithFallback
  | (Omit<ErrorBoundaryPropsWithFallback, 'fallback'> & {
      fallback?: never;
    });

/**
 * A guard that catches errors and displays an error feedback component automatically.
 */
const ErrorGuard: React.FC<ErrorGuardProps> = ({
  fallbackRender,
  fallback,
  children,
}) => {
  const devLogger = useDevLogger();
  const logger = useLogger();
  const location = useLocation();
  const app = useApp();
  const sentry = useSentry();

  const sentryErrorHandler = sentry.reactErrorHandler();

  const onError = React.useCallback<Required<ErrorGuardProps>['onError']>(
    (error: Error, info: React.ErrorInfo) => {
      devLogger.fatal(buildLogObject(error, info), error.message);
      logger.fatal(buildLogObject(error, info), error.message);
      sentryErrorHandler(error, info);
      app.setLoading(false);
    },
    [logger, devLogger, app.setLoading, sentryErrorHandler]
  );

  return (
    <ErrorBoundary
      resetKeys={[location.pathname, location.search, location.hash]}
      fallbackRender={(props) => {
        if (!_.isUndefined(fallbackRender)) {
          return fallbackRender(props);
        }

        if (!_.isUndefined(fallback)) {
          return fallback;
        }

        return (
          <HttpErrorFeedback
            error={props.error}
            onReload={props.resetErrorBoundary}
          />
        );
      }}
      onError={onError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorGuard;

/*
|------------------
| Utils
|------------------
*/

/**
 * Build a log object from an error and an error info.
 *
 * @param error A JavaScript error.
 * @param info A React error info.
 * @returns A log object.
 */
const buildLogObject = (error: Error, info: React.ErrorInfo) => {
  const httpError = isHttpError(error) ? error : null;

  return {
    http: _.isNil(httpError)
      ? null
      : {
          code: httpError.errorCode,
          status: httpError.statusCode,
        },
    err: {
      message: error.message,
      stack: error.stack,
      name: error.name,
      cause: error.cause,
    },
    react: {
      stack: info.componentStack,
      digest: info.digest,
    },
  };
};
