import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconExclamationCircle,
  LinearProgress,
  Link,
  Typography,
} from '@joggrdocs/riker';

import api from '@stargate/api';
import { ButtonLink } from '@stargate/components/Buttons';
import { Page } from '@stargate/components/Utils';
import { useUser } from '@stargate/features/user';
import { useUserOnboarding } from '@stargate/features/user-onboarding';
import useSearchParams from '@stargate/hooks/use-search-params';
import * as http from '@stargate/lib/http';
import { useNotify } from '@stargate/lib/notify';
import { useNavigate } from '@stargate/routes';

export const GitHubAuthorizeUserPage: React.FC = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const [, authorizeActions] = api.useRequestClient('POST /github/authorize');
  const [searchParams] = useSearchParams<{ code: string }>();
  const authenticatedUser = useUser();
  const [fatalError, setFatalError] = React.useState<Error | undefined>();
  const userOnboarding = useUserOnboarding();

  const authorize = async (code: string) => {
    try {
      await authorizeActions.execute({
        body: {
          code,
        },
      });

      const user = await authenticatedUser.load({ clearCache: true });

      if (_.isNil(user)) {
        setFatalError(new Error('Failed to get user data post-authorization'));
        notify.error('Failed to fetch user data');
        return;
      }

      if (user.metadata.authorizedWithGithub !== true) {
        setFatalError(new Error('Unknown error'));
        notify.error('Failed to authorize with GitHub');
        return;
      }

      if (user.metadata.onboarded !== true) {
        await userOnboarding.markStepCompleted('github-authorize');

        // If a GitHub app is installed, mark the step as completed
        if (user.metadata.githubAppInstalled) {
          await userOnboarding.markStepCompleted('github-install-app');
        }

        notify.success('Successfully authorized with GitHub');
        navigate('utility.onboarding', {
          replace: true,
        });
        return;
      }

      notify.success('Successfully re-authorized with GitHub');
      // Navigate to the root page
      navigate('app.root', {
        replace: true,
      });
      // Force a reload to ensure the user is reloaded
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (err) {
      if (err instanceof Error) {
        setFatalError(err);
      } else {
        setFatalError(new Error('Unknown error'));
      }
      notify.error('Failed GitHub authorization');
    }
  };

  /*
  |------------------
  | Effects
  |------------------
  */

  hookz.useMountEffect(async () => {
    if (searchParams.code) {
      // Refresh the user from the server
      void authorize(searchParams.code);
    } else {
      setFatalError(http.createError(400, 'UNK_000', 'No code provided'));
    }
  });

  return (
    <Page
      id='authorize-github'
      title='Authorize | Authorizing with GitHub'
      errors={fatalError ? [fatalError] : []}
      render={() => (
        <Box sx={{ width: '100%' }}>
          {fatalError && (
            <Dialog open>
              <DialogTitle
                startIcon={<IconExclamationCircle />}
                title='GitHub Authorization Failed'
              />
              <DialogContent>
                <Typography variant='body1' component='p'>
                  You failed to authorize with GitHub, please contact{' '}
                  <Link href='mailto:support@joggr.io?subject=GitHub+Authorize+Error'>
                    support@joggr.io
                  </Link>
                </Typography>
              </DialogContent>
              <DialogActions
                sx={{
                  mx: 2,
                }}
              >
                <ButtonLink
                  to='root.logout'
                  variant='contained'
                  color='primary'
                  fullWidth
                >
                  Exit Joggr
                </ButtonLink>
              </DialogActions>
            </Dialog>
          )}
          <Typography variant='h6' component='p'>
            Authorizing with GitHub...
          </Typography>
          <LinearProgress />
        </Box>
      )}
    />
  );
};
