import { Badge, Box, Tooltip, badgeClasses, colors } from '@joggrdocs/riker';
import React from 'react';

import { useDevTools } from '@stargate/hooks';
import { generateComponentClasses } from '@stargate/theme';

import { useFrigadeTarget } from '../hooks/use-frigade-target';

export const frigadeTargetClasses = generateComponentClasses('Frigade-Target', [
  'root',
] as const);

export interface FrigadeTargetProps extends React.ComponentProps<typeof Box> {
  /**
   * The id of the target, that will be used to trigger the Tour, Badge or other components.
   */
  targetId: string;
}

export const FrigadeTarget = React.forwardRef<HTMLElement, FrigadeTargetProps>(
  ({ targetId, children, ...restProps }, ref) => {
    const frigadeTarget = useFrigadeTarget(targetId);
    const devtools = useDevTools();
    const [active, setActive] = React.useState(false);

    const sx = React.useMemo(() => {
      return {
        ...restProps.sx,
        // @ts-expect-error - We need to add the display property to the sx prop
        display: restProps.sx?.display || 'inline-block',
      };
    }, [restProps.sx]);

    const props = {
      ...restProps,
      sx,
      className: frigadeTargetClasses.root,
      id: frigadeTarget.id,
      ref,
    };

    return (
      <React.Fragment>
        {devtools.enabled ? (
          <Badge
            badgeContent='FG'
            color='secondary'
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            sx={{
              [`& .${badgeClasses.badge}`]: {
                fontSize: '6px',
                color: '#fff',
                // FrigadeBlue
                backgroundColor: '#0171F8',
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              navigator.clipboard.writeText(frigadeTarget.id);
              alert(
                `Copied frigade target id: ${frigadeTarget.id} to clipboard`
              );
            }}
          >
            <Tooltip
              title={`frigade: #${frigadeTarget.id}`}
              placement='top'
              slotProps={{
                tooltip: {
                  sx: {
                    cursor: 'pointer',
                    backgroundColor: colors.blue[800],
                    color: '#fff',
                    border: `1px solid ${colors.blue[300]}`,
                  },
                },
              }}
              open={active}
            >
              <Box
                {...props}
                sx={{
                  ...sx,
                  // @ts-expect-error - We need to add the border property to the sx prop
                  border: active ? `1px solid ${colors.red[300]}` : sx?.border,
                }}
              >
                {children}
              </Box>
            </Tooltip>
          </Badge>
        ) : (
          <Box {...props}>{children}</Box>
        )}
      </React.Fragment>
    );
  }
);
