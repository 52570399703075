import _ from 'lodash';

import * as http from '@stargate/lib/http';
import * as Auth0 from '@stargate/vendors/auth0';

/**
 * Send an authorized HTTP request to the backend, & refreshes the Auth0 token if its expired.
 */
export const useHTTPClient = () => {
  const auth0 = Auth0.useAuth0();
  const httpErrors = http.useHttpErrors();

  const refreshAuth0Token = async () => {
    if (!auth0.isAuthenticated) {
      throw httpErrors.fatal('UNK_000', 'User is not authenticated');
    }

    if (Auth0.authToken.isExpired()) {
      const tokenResult = await auth0.getAccessTokenSilently();
      Auth0.authToken.write(tokenResult);
    }
  };

  return {
    async fetchAuthorized<T extends http.FetchResult>(
      url: string,
      options: http.FetchOptions = {}
    ) {
      await refreshAuth0Token();
      return await http.fetchAuthorized<T>(url, options);
    },
    fetch: http.fetchUnauthorized,
  };
};
