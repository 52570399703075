import * as hookz from '@react-hookz/web';
import _ from 'lodash';
import React from 'react';
import type * as TypeFest from 'type-fest';

import apiClient from '@stargate/api';

import {
  type ActiveWorkspace,
  useWorkspace,
} from '@stargate/features/workspaces';
import type {
  DirectoryAPIBody,
  DirectoryAPIResult,
  DirectoryTree,
  DirectoryTreeFilter,
  DirectoryTreeNode,
  DocumentTagsType,
} from '../types';
import useDirectoryTreeStore from './use-directory-tree-store';

/*
|==========================================================================
| useDirectoryTree
|==========================================================================
|
| Actions to perform on the directory tree, including fetching, creating, filtering, etc.
|
*/

export interface UseDirectoryTreeHook {
  /**
   * Whether the directory tree is loading or not.
   */
  loading: boolean;

  /**
   * The error that occurred while fetching the directory tree.
   */
  error?: Error;

  /**
   * Whether the directory tree is saving or not.
   */
  saving: boolean;

  /**
   * The active workspace.
   */
  workspace?: ActiveWorkspace;

  /**
   * The directory tree.
   */
  tree?: DirectoryTree;

  /**
   * The ids of the directories & documents that are expanded in the directory tree.
   */
  expandedNodes: string[];

  /**
   * The id of the directory or document that is selected in the directory tree.
   */
  selectedNode: string | null;

  /**
   * Triggers a fetch of the directory tree from the API.
   */
  onLoad: () => Promise<void>;

  /**
   * Triggers a (in-memory) filter of the directory tree that was fetched from the API.
   *
   * @param filter A filter to apply to the directory tree
   */
  onFilter: (filter: DirectoryTreeFilter) => void;

  /**
   * Triggers the selection of a directory or document in the directory tree.
   *
   * @param id The id of the directory or document to select
   */
  onItemSelectionToggle: (id: string) => void;

  /**
   * Expands a list of directories in the directory tree.
   *
   * @param ids A list of directory ids to expand
   */
  onExpandNodes: (ids: string[]) => void;

  /**
   * Collapses a list of directories in the directory tree.
   *
   * @param ids A list of directory ids to collapse
   */
  onCollapseNodes: (ids: string[]) => void;

  /**
   * Adds a new directory in the tree.
   *
   * @param data The data for the directory to add to the tree
   */
  onCreateDirectory: (
    data: TypeFest.SetRequired<Exclude<DirectoryAPIBody, undefined>, 'title'>
  ) => Promise<void>;

  /**
   * Updates the data for a directory in the tree.
   *
   * @param data The data for the directory to update to the tree
   */
  onUpdateDirectory: (
    directoryId: string,
    data: DirectoryAPIBody
  ) => Promise<void>;

  /**
   * Removes (Deletes) a directory from the tree.
   *
   * @param directoryId The ID of the directory to remove from the tree
   */
  onDeleteDirectory: (directoryId: string) => Promise<void>;

  /**
   * Move a directory to a different directory.
   *
   * @param payload The payload to move a directory from one directory to another
   * @param payload.fromDirectoryId The ID of the directory to move the directory from, or null if the directory is in the root
   * @param payload.toDirectoryId The ID of the directory to move the directory to, or null if the directory should be moved to the root
   * @param payload.directoryId The ID of the directory to move
   * @returns
   */
  onMoveDirectory: (payload: {
    fromDirectoryId: string | null;
    toDirectoryId: string | null;
    directoryId: string;
  }) => Promise<void>;

  /**
   * Adds a document to a directory.
   *
   * @param directoryId The ID of the directory to update
   * @param document The payload to add a document to a directory
   * @param document.id The ID of the document to add to the directory
   */
  onCreateDirectoryDocument: (
    directoryId: string | null,
    documentId: string
  ) => Promise<void>;

  /**
   * Move a document to a different directory or back to root (`toDirectoryId: null`)
   *
   * @param payload The payload to move a document from one directory to another
   * @param payload.fromDirectoryId The ID of the directory to move the document from, or null if the document is in the root
   * @param payload.toDirectoryId The ID of the directory to move the document to, or null if the document should be moved to the root
   * @returns
   */
  onMoveDirectoryDocument: (payload: {
    fromDirectoryId: string | null;
    toDirectoryId: string | null;
    documentId: string;
  }) => Promise<void>;

  /**
   * Calculates new order after node is dropped below another node (aka between two nodes)
   *
   * @param payload.parentId The ID of the parent node that contains the target node (itemId) and the previous node
   * @param payload.previousNodeId The ID of the node that should sit above the target node (itemId)
   * @param payload.itemId The ID of the target node
   * @returns
   */
  onReorderNode: (payload: {
    previousNodeId: string | null;
    parentNodeId: string | null;
    itemId: string;
  }) => Promise<void>;
}

export const useDirectoryTree = (): UseDirectoryTreeHook => {
  const [storeState, storeActions] = useDirectoryTreeStore();
  const workspace = useWorkspace();
  // const joggrDocBreadcrumbs = useJoggrDocBreadcrumbs();
  const [, readDirectoryTreeActions] = apiClient.useRequestClient(
    'GET /directory-tree'
  );
  const [createDirState, createDirActions] = apiClient.useRequestClient(
    'POST /directory-tree/directories'
  );
  const [updateDirState, updateDirActions] = apiClient.useRequestClient(
    'PUT /directory-tree/directories/:id'
  );
  const [deleteDirState, deleteDirActions] = apiClient.useRequestClient(
    'DELETE /directory-tree/directories/:id'
  );
  const [upsertDirDocState, upsertDirDocActions] = apiClient.useRequestClient(
    'PUT /directory-tree/directories/documents'
  );
  const [deleteDirDocState, deleteDirDocActions] = apiClient.useRequestClient(
    'DELETE /directory-tree/directories/:directoryId/documents/:documentId'
  );
  const [readError, setReadError] = React.useState<Error | undefined>();

  /*
  |------------------
  | Computed
  |------------------
  */

  const saving = React.useMemo(() => {
    return _.some([
      createDirState.status === 'loading',
      updateDirState.status === 'loading',
      deleteDirState.status === 'loading',
      upsertDirDocState.status === 'loading',
      deleteDirDocState.status === 'loading',
    ]);
  }, [
    createDirState,
    updateDirState,
    deleteDirState,
    upsertDirDocState,
    deleteDirDocState,
  ]);

  const filteredTree = React.useMemo(() => {
    return storeState.filters && storeState.data
      ? filterTree(storeState.data, storeState.filters)
      : storeState.data ?? undefined;
  }, [storeState.data, storeState.filters]);

  /*
  |------------------
  | Callbacks
  |------------------
  */

  const onLoad = React.useCallback<UseDirectoryTreeHook['onLoad']>(async () => {
    storeActions.setLoading(true);
    try {
      const result = await readDirectoryTreeActions.execute();
      const itemsWithAncestry = result.tree.map((item) => ({
        ...item,
        ancestors: getAncestors(result.tree, item),
      }));
      void workspace.load();

      storeActions.setData(itemsWithAncestry);
    } catch (error) {
      if (error instanceof Error) {
        setReadError(error);
      }
    }
  }, [storeActions, readDirectoryTreeActions, workspace]);

  const onFilter = hookz.useDebouncedCallback(
    (filter: DirectoryTreeFilter) => {
      storeActions.setFilters(filter);
    },
    [storeActions],
    300
  );

  const onItemSelectionToggle = React.useCallback<
    UseDirectoryTreeHook['onItemSelectionToggle']
  >((id) => {
    storeActions.onItemSelectionToggle(id);
  }, []);

  const onExpandNodes = React.useCallback<
    UseDirectoryTreeHook['onExpandNodes']
  >(
    (ids) => {
      storeActions.onExpandNodes(ids);
    },

    []
  );

  const onCollapseNodes = React.useCallback<
    UseDirectoryTreeHook['onCollapseNodes']
  >((ids) => {
    storeActions.onCollapseNodes(ids);
  }, []);

  const onCreateDirectory = React.useCallback<
    UseDirectoryTreeHook['onCreateDirectory']
  >(async (directory) => {
    await createDirActions.execute({
      body: {
        ...directory,
        parentId: directory.parentId ?? undefined,
      },
    });
  }, []);

  const onUpdateDirectory = React.useCallback<
    UseDirectoryTreeHook['onUpdateDirectory']
  >(async (directoryId, data) => {
    await updateDirActions.execute({
      params: {
        id: directoryId,
      },
      body: data,
    });
  }, []);

  const onDeleteDirectory = React.useCallback<
    UseDirectoryTreeHook['onDeleteDirectory']
  >(
    async (directoryId) => {
      await deleteDirActions.execute({
        params: {
          id: directoryId,
        },
      });
    },
    [deleteDirActions]
  );

  const onMoveDirectory = React.useCallback<
    UseDirectoryTreeHook['onMoveDirectory']
  >(async (payload) => {
    // We need to prevent moving a directory into itself
    if (payload.toDirectoryId !== payload.directoryId) {
      await updateDirActions.execute({
        params: {
          id: payload.directoryId,
        },
        body: {
          // @ts-expect-error schema generated API contract is wrong - https://github.com/bcherny/json-schema-to-typescript/pull/535
          parentId: payload.toDirectoryId ?? null,
        },
      });
    }
  }, []);

  const onCreateDirectoryDocument = React.useCallback<
    UseDirectoryTreeHook['onCreateDirectoryDocument']
  >(async (directoryId, documentId) => {
    await upsertDirDocActions.execute({
      body: {
        documentId,
        // @ts-expect-error schema generated API contract is wrong
        directoryId: directoryId ?? null,
      },
    });
  }, []);

  const onMoveDirectoryDocument = React.useCallback<
    UseDirectoryTreeHook['onMoveDirectoryDocument']
  >(async (payload) => {
    if (_.isNil(payload.toDirectoryId) && !_.isNil(payload.fromDirectoryId)) {
      await deleteDirDocActions.execute({
        params: {
          directoryId: payload.fromDirectoryId,
          documentId: payload.documentId,
        },
      });
    }

    if (!_.isNil(payload.toDirectoryId)) {
      await upsertDirDocActions.execute({
        body: {
          directoryId: payload.toDirectoryId,
          documentId: payload.documentId,
          order: undefined,
        },
      });
    }
  }, []);

  const calculateReorder = React.useCallback(
    (payload: {
      previousNodeId: string | null;
      parentNodeId: string | null;
      itemId: string;
    }) => {
      const { previousNodeId, parentNodeId } = payload;
      const tree = storeState.data ?? [];
      const previousNode = findNode(tree, previousNodeId);
      const siblings = findChildrenNodes(tree, parentNodeId);
      const previousNodeIndex = siblings.findIndex(
        (node) => node.id === previousNodeId
      );
      const nextNode =
        previousNodeIndex >= 0 ? siblings[previousNodeIndex + 1] : null;

      // Handle the case where the node is dropped at the top of the tree row
      if (_.isNil(previousNode)) {
        const firstItem = _.first(siblings);
        // @todo remove order "??" once we run migration script to set all orders to non-null - https://linear.app/joggr/issue/ENG-1931/update-schema-to-make-order-non-null
        // Handle all other use cases, the order SHOULD NEVER be Nil since we run the script to set it to non-null in the DB
        return (firstItem?.order ?? 0) / 2;

        // Handle if at the bottom of the tree row
      }
      if (_.isNil(nextNode)) {
        // @todo remove order "??" once we run migration script to set all orders to non-null - https://linear.app/joggr/issue/ENG-1931/update-schema-to-make-order-non-null
        // Handle all other use cases, the order SHOULD NEVER be Nil since we run the script to set it to non-null in the DB
        return (previousNode.order ?? 0) + 100;
      }
      // @todo remove order "??" once we run migration script to set all orders to non-null - https://linear.app/joggr/issue/ENG-1931/update-schema-to-make-order-non-null
      // Handle all other use cases, the order SHOULD NEVER be Nil since we run the script to set it to non-null in the DB
      return ((previousNode.order ?? 0) + (nextNode.order ?? 0)) / 2;
    },
    [storeState.data]
  );

  const onReorderNode = React.useCallback<
    UseDirectoryTreeHook['onReorderNode']
  >(
    async ({ previousNodeId, parentNodeId, itemId }) => {
      const node = findNode(storeState.data ?? [], itemId);
      if (_.isNil(node)) {
        throw new Error(`Node with ID ${itemId} not found`);
      }

      // @todo make this less aggressive as right now it can re-request every time a node is dropped onto the it's own previous node
      // continuously pushing the reorder float farther...
      const newOrder = calculateReorder({
        previousNodeId,
        parentNodeId,
        itemId,
      });

      if (node.nodeType === 'directory') {
        await updateDirActions.execute({
          params: {
            id: node.id,
          },
          body: {
            ...node,
            order: newOrder,
            // @ts-expect-error schema generated API contract is wrong - https://github.com/bcherny/json-schema-to-typescript/pull/535
            parentId: parentNodeId,
          },
        });
        await onLoad();
      } else {
        await upsertDirDocActions.execute({
          body: {
            // @ts-expect-error schema generated API contract is wrong - https://github.com/bcherny/json-schema-to-typescript/pull/535
            directoryId: parentNodeId,
            documentId: node.id,
            order: newOrder,
          },
        });
        await onLoad();
      }
    },

    [storeState, calculateReorder]
  );

  return {
    /*
    |------------------
    | State
    |------------------
    */

    loading: storeState.loading || workspace.loading,
    error: readError ?? workspace.error ?? undefined,
    saving,

    /*
    |------------------
    | Data
    |------------------
    */

    workspace: workspace.data ?? undefined,
    tree: filteredTree,
    expandedNodes: storeState.expandedNodes,
    selectedNode: storeState.selectedNode,

    /*
    |------------------
    | Callbacks
    |------------------
    */

    onLoad,
    onFilter,
    onItemSelectionToggle,
    onExpandNodes,
    onCollapseNodes,
    onCreateDirectory,
    onUpdateDirectory,
    onDeleteDirectory,
    onMoveDirectory,
    onCreateDirectoryDocument,
    onMoveDirectoryDocument,
    onReorderNode,
  };
};

export default useDirectoryTree;

/*
|------------------
| Utils
|------------------
*/

/**
 * Find a node in the tree by its ID.
 *
 * @param tree The tree to search
 * @param itemId The ID of the node to find
 * @returns The node if found, otherwise null
 */
const findNode = (
  tree: DirectoryTree,
  itemId: string | null
): DirectoryTreeNode | null => {
  if (_.isNil(itemId)) {
    return null;
  }

  return tree.find((node) => node.id === itemId) ?? null;
};

/**
 * Find the children of a node in the tree.
 *
 * @param tree The tree to search
 * @param itemId The ID of the node to find the children of
 * @returns The children nodes if found, otherwise an empty array
 */
const findChildrenNodes = (
  tree: DirectoryTree,
  itemId: string | null
): DirectoryTreeNode[] => {
  return tree.filter((child) => child.parentId === itemId);
};

/*
|------------------
| Utils: Filters
|------------------
*/

const validateTitleMatch = (
  title: string,
  filter: DirectoryTreeFilter | null
): boolean => {
  if (!filter?.text) {
    return true;
  }

  return title.toLowerCase().includes(filter.text.toLowerCase());
};

const validateGithubRepositoryMatch = (
  githubRepositoryId: string,
  filter: DirectoryTreeFilter | null
): boolean => {
  if (!filter?.githubRepositoryIds || filter.githubRepositoryIds.length === 0) {
    return true;
  }

  return filter.githubRepositoryIds
    .map((id) => id.toString())
    .includes(githubRepositoryId.toString());
};

const validateTagsMatch = (
  tags: DocumentTagsType,
  filter: DirectoryTreeFilter | null
): boolean => {
  if (!filter?.tagIds || filter.tagIds.length === 0 || !tags) {
    return true;
  }

  return _.some(tags, (tag) => filter.tagIds?.includes(tag.id));
};

const matchNodes = (
  filter: DirectoryTreeFilter | null,
  tree: DirectoryTree
): DirectoryTree => {
  if (!validateFilter(filter) || !tree || tree.length === 0) {
    return tree;
  }

  return tree.filter((node) => {
    // Check title match first
    if (!validateTitleMatch(node.title, filter)) {
      return false;
    }

    // Directories do not have tags
    if (
      _.isArray(filter?.tagIds) &&
      filter.tagIds.length > 0 &&
      node.nodeType === 'directory'
    ) {
      return false;
    }

    // Directories do not have githubRepositoryIds
    if (
      _.isArray(filter?.githubRepositoryIds) &&
      filter.githubRepositoryIds.length > 0 &&
      node.nodeType === 'directory'
    ) {
      return false;
    }

    // If the node has tags, validate them
    if ('tags' in node && !validateTagsMatch(node.tags, filter)) {
      return false;
    }

    // If the node has a repositoryId, validate it, else return true
    if (
      'repositoryId' in node &&
      !validateGithubRepositoryMatch(node.repositoryId, filter)
    ) {
      return false;
    }

    return true;
  });
};

const validateFilter = (filter: DirectoryTreeFilter | null): boolean => {
  return _.some([
    !_.isNil(filter?.text),
    _.isArray(filter?.tagIds) && filter.tagIds.length > 0,
    _.isArray(filter?.githubRepositoryIds) &&
      filter.githubRepositoryIds.length > 0,
  ]);
};

/**
 * Filters a tree.
 *
 * @param tree A tree to filter
 * @param filter A filter to apply to the tree
 * @returns A tree that matches the filter
 */
const filterTree = (
  tree: DirectoryTree,
  filter: DirectoryTreeFilter | null
): DirectoryTree => {
  if (!validateFilter(filter)) {
    return tree;
  }

  const matchedNodes = matchNodes(filter, tree);
  const newTree = [...matchedNodes];

  const allAncestors = newTree.flatMap(({ ancestors }) => ancestors);

  // biome-ignore lint/complexity/noForEach: We need to iterate over the ancestors to find the nodes
  allAncestors.forEach((ancestor) => {
    const nodeInTree = tree.find((node) => node.id === ancestor.id);
    if (!_.isNil(nodeInTree)) {
      newTree.push(nodeInTree);
    }
  });

  return _.uniqBy(newTree, 'id');
};

const getAncestors = (
  tree: DirectoryAPIResult['tree'],
  node: DirectoryAPIResult['tree'][number]
): Array<{ id: string }> => {
  const ancestors: Array<{ id: string }> = [];
  let currentItem = node;

  while (!_.isNil(currentItem)) {
    const parentItem = !_.isNil(currentItem.parentId)
      ? tree.find((node) => node.id === currentItem.parentId)
      : null;
    if (!_.isNil(parentItem)) {
      ancestors.unshift(parentItem);
      currentItem = parentItem;
    } else {
      break;
    }
  }

  return ancestors;
};
