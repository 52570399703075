import { createLocale } from '../../lib/locale-factory';

/**
 * The HTTP error codes for the application.
 */
const codes = createLocale('codes', {
  DOC_NF001: 'JoggrDoc not found.',
  DOC_NF002: "JoggrDoc found but markdown file doesn't exist in GitHub.",
  IMP_001:
    'Some of the provided imports contain file paths that are associated with existing JoggrDocs.',
  IMP_002:
    'JoggrDocs are either already imported or the contents of one of the files contains JoggrDoc metadata. You can remove the metadata in the file manually and try again.',
  IMP_003:
    'JoggrDoc is missing key data such as title, description, or filePath in the import.',
  UNK_000: 'An unknown error occurred.',
});

export default createLocale('error', {
  ...codes,

  /*
  |------------------
  | Types
  |------------------
  */

  'type.unknown.title': 'Houston, we have a problem!',
  'type.unknown.description':
    'Well looks like we had an oopsie, as an "unknown error" has occurred. Please try again later, refresh the page, or shoot us an email if the problem persists.',
  'type.internal-server-error.title': 'We screwed up.',
  'type.internal-server-error.description':
    "Sorry, it looks like our server isn't doing so hot... Please try again later, refresh the page, or shoot us an email if the problem persists.",
  'type.not-found.title': 'Took a wrong turn?',
  'type.not-found.description':
    'Seems that you are lost as the page you are looking for does not exist, or it moved, or you typed the wrong URL (or maybe we screwed something up). Please go back to the homepage or shoot us an email if the problem persists.',
  'type.bad-request.title': 'Looks like you made a mistake...',
  'type.bad-request.description':
    'Sorry, the request you made seems to be invalid. Please try again later, refresh the page, or shoot us an email if the problem persists.',
  'type.unauthorized.title': 'Umm, you need to login...',
  'type.unauthorized.description': 'Sorry, looks like you need to login!',
  'type.forbidden.title': 'Not gonna happen.',
  'type.forbidden.description':
    'Sorry, you are not authorized to access this page.',
});
