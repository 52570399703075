import React from 'react';

import { Box, type BoxProps, Stack, Typography } from '@joggrdocs/riker';

/*
|==========================================================================
| Feedback
|==========================================================================
|
| Feedback is a component that displays a message to the user, based on the results of an action they have taken or
| a state of the application.
|
*/

export interface FeedbackProps {
  title: string | React.ReactNode;
  titleTypographyProps?: React.ComponentProps<typeof Typography>;
  message?: string | React.ReactNode;
  messageTypographyProps?: React.ComponentProps<typeof Typography>;
  imagery?: React.ReactNode;
  actions?: React.ReactNode;
  center?: boolean;
  sx?: BoxProps['sx'];
}

const getSx = (props: FeedbackProps): BoxProps['sx'] => {
  const { center = true, sx } = props;
  return {
    margin: center ? '0 auto' : null,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: center ? 'center' : 'flex-start',
    justifyContent: center ? 'center' : 'flex-start',
    ...sx,
  };
};

export const Feedback = React.forwardRef<HTMLDivElement, FeedbackProps>(
  (
    {
      imagery,
      title,
      titleTypographyProps,
      message,
      messageTypographyProps,
      actions,
    },
    ref
  ) => {
    return (
      <Box
        ref={ref}
        sx={getSx({
          imagery,
          title,
          titleTypographyProps,
          message,
          messageTypographyProps,
          actions,
        })}
      >
        {imagery}
        {imagery && <Box sx={{ mt: 2 }} />}
        <Typography variant='h1' gutterBottom {...titleTypographyProps}>
          {title}
        </Typography>
        {message && (
          <Typography
            variant='body1'
            whiteSpace='initial'
            paragraph
            gutterBottom
            textAlign='center'
            sx={{ maxWidth: '780px' }}
            {...messageTypographyProps}
          >
            {message}
          </Typography>
        )}
        {actions && (
          <Stack direction='row' spacing={1}>
            {actions}
          </Stack>
        )}
      </Box>
    );
  }
);
Feedback.displayName = 'Feedback';
