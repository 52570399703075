import { QueryClientProvider as ReactQueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import { useDevTools } from '@stargate/hooks';
import { queryClient } from '@stargate/lib/query/query-client';

/**
 * Pre-configured QueryClientProvider for `@tanstack/react-query`.
 */
export const QueryClientProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const devtools = useDevTools();
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
      {devtools.enabled && (
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition='bottom-left'
        />
      )}
    </ReactQueryClientProvider>
  );
};
