import {
  Avatar,
  Box,
  Divider,
  IconBrandGithub,
  IconCircleCheck,
  IconCircleX,
  IconMail,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Stack,
  Typography,
  useTheme,
} from '@joggrdocs/riker';
import React from 'react';

import type { User } from '@stargate/features/user';

export interface UserProfileProps {
  authenticatedUser: User;
}

function UserProfile(props: UserProfileProps) {
  const { authenticatedUser } = props;
  const theme = useTheme();
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, bgcolor: 'transparent', display: 'flex' }}>
        <Avatar
          alt={authenticatedUser.name}
          src={authenticatedUser.picture}
          sx={{
            width: 80,
            height: 80,
          }}
        />
        <Stack sx={{ ml: 1, mt: 'auto', mb: 'auto' }}>
          <Typography variant='h3' gutterBottom>
            {authenticatedUser.name}
          </Typography>
          <Stack direction='row' spacing={0.5} alignItems='center'>
            {authenticatedUser.emailVerified && (
              <IconCircleCheck
                style={{
                  verticalAlign: 'bottom',
                  color: theme.palette.success.main,
                  fontSize: '16px',
                }}
              />
            )}
            {!authenticatedUser.emailVerified && (
              <IconCircleX style={{ verticalAlign: 'bottom' }} />
            )}
            <Typography>
              {authenticatedUser.emailVerified ? 'Verified' : 'Not Verified'}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <Divider sx={{ mt: 2, mb: 2 }} />
      <List>
        <ListItem>
          <ListItemIcon>
            <IconMail />
          </ListItemIcon>
          {authenticatedUser.email}
        </ListItem>
        {authenticatedUser.githubUsername && (
          <ListItem>
            <ListItemIcon>
              <IconBrandGithub />
            </ListItemIcon>
            <Link
              href={`https://github.com/${authenticatedUser.githubUsername}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              {authenticatedUser.githubUsername}
            </Link>
          </ListItem>
        )}
      </List>
    </React.Fragment>
  );
}

export default UserProfile;
